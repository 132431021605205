.App {
  text-align: center;
}

.App-header {
  h3 {
    color: #ff0000;
    text-align: center;
    font-size: calc(10px + 3vmin);
  }

  margin-top: 1em;
  text-align: center;
  font-size: calc(10px + 1.5vmin);
  font-weight: 300;
}

.App-main {
  .title {
    font-size: calc(10px + 1vmin);
    color: #ff0000;
    text-align: left;
  }

  .body {
    font-size: calc(10px + 1vmin);
  }

  .btn {
    padding: 0.2rem;
  }
}

.App-footer {
  text-align: center;
  font-size: 1em;
  font-weight: 400;
  color: #808080;
}
